import { SafeStoreInstance } from 'features/Multisig';
import { TransferContext } from 'features/Transfer/context/TransferContext';
import { observer } from 'mobx-react';
import { useContext, useMemo, useState, useEffect } from 'react';
import { useNavigate } from 'react-router';
import AppContext from 'shared/contexts/AppContext';

import { CreateOtpModal } from 'shared/components/OtpModal/CreateOtpModal';

import * as S from '../styled';
import * as C from 'shared/components/AbstractComponents';
import { AddressManagement } from 'shared/components/AddressManagement';
import { CurrencyType } from 'shared/types';
import { getCurrencyLogo } from 'shared/helpers/currencies';

import { getTransferError } from 'features/Transfer/functions';

import { UnsetOtp } from 'shared/components/OtpModal/UnsetOtp';

import { AccountsStoreInstance, CurrenciesStoreInstance } from 'services';
import { Whitelist } from 'features/Transfer/types';

const NETWORKS = ['ethereum', 'tron', 'solana'];

export const TransferArea = observer(() => {
  const navigate = useNavigate();
  const {
    setMultisigTab,
    accounts,
    appSettings,
    transactionStatus,
    setTransactionStatus,
    user,
  } = useContext(AppContext);

  const {
    from,
    to,
    amount,
    changeAmount,
    bothSelected,
    toWalletHasDepositAddress,
    isFromWallet,
    isFromMultisig,
    isCrossWallet,
    bothAreFutures,
    withdrawFee,
    minWithdrawLimit,
    showFees,
    chosenCurrency,
    isValueMinExceed,
    amountDisplay,
    handleIsConfirming,
    isConfirmAvailable,
    isChosenCurrencyAvailable,
    isTfaRequired,
    aboutToExecute,
    submitTransaction,
    comment,
    error,
    setError,
    isSubmitting,
    couldAddTransaction,
    couldAddTransactionProposal,
    resetTo,
    resetFrom,
  } = useContext(TransferContext);

  const { whitelists } = AccountsStoreInstance;

  useEffect(() => {
    const txAddress = to?.wallet?.deposit_addresses[0]?.deposit_address;
    setTxAddress(txAddress);
    const isWhitelisted = Boolean(
      whitelists?.find(
        ({ deposit_address, currency }: Whitelist) =>
          deposit_address === txAddress && currency === to.wallet?.currency,
      ),
    );
    setTxAddressIsWhitelisted(isWhitelisted);
  }, [to, whitelists]);

  const [txNetwork, setTxNetwork] = useState();
  const [txAddress, setTxAddress] = useState();
  const [depositAddressIsWhitelisted, setTxAddressIsWhitelisted] =
    useState(false);
  const { openModal } = useContext(AppContext);

  const { rates, getCurrencyPrecision } = CurrenciesStoreInstance;

  const toExchange = to?.account?.exchange;

  const [management, setManagement] = useState(false);
  const [opened, setOpened] = useState(false);
  const [network, setNetwork] = useState(NETWORKS[0]);
  const [step, setStep] = useState('amount');

  const isTransactionStatusOk = transactionStatus?.api_creation_status === 'ok';

  useEffect(() => {
    return () => {
      resetTo();
      resetFrom();
    };
  }, []);

  useEffect(() => {
    if (!isTransactionStatusOk) setStep('amount');
  }, [from]);

  useEffect(() => {
    setOpened(false);
    setManagement(false);
    if (!isTransactionStatusOk) setStep('amount');
    setStep('amount');
    setTransactionStatus(null);
    setError(undefined);
  }, [to]);

  const isBinanceCross = isCrossWallet && from?.account?.exchange === 'BINANCE';

  const label = useMemo(() => {
    if (bothAreFutures) {
      return 'Transfers between futures wallets are not available';
    }

    if (isFromMultisig) {
      const onClick = () => {
        const { addRecentlyUsedSafe } = SafeStoreInstance;
        addRecentlyUsedSafe(String(from?.wallet?.deposit_address));
        setMultisigTab('assets');
        setTimeout(() => navigate('/multisig-wallets'), 0);
      };

      return (
        <S.GoToWalletsMessage>
          To transfer from this multisig wallet{' '}
          <S.GoToWalletsButton onClick={onClick} type="button">
            <span>Go to wallets</span>
            <S.IconGoToWallets />
          </S.GoToWalletsButton>
        </S.GoToWalletsMessage>
      );
    }

    if (isFromWallet) {
      return 'Transfering from standalone wallets is unavailable';
    }

    if (!txAddress && !isCrossWallet && !isTransactionStatusOk) {
      return 'Transfer to this wallet is unavailable';
    }

    if (
      !depositAddressIsWhitelisted &&
      !isBinanceCross &&
      !isTransactionStatusOk
    ) {
      return 'The deposit address is missing in the whitelist';
    }

    return 'Propose transaction';
  }, [from, to, amount, txAddress, depositAddressIsWhitelisted]);

  const handleCreateTransaction = (step: string) => {
    if (!user?.has_hotp_secret && appSettings.is_hotp_enabled) {
      openModal({
        title: 'Security Verification',
        component: () => <UnsetOtp />,
      });
    } else {
      setStep(step);
    }
  };

  const submitAfterOtp = async (otp: string, tfa?: string) => {
    await submitTransaction({
      fromWallet: from?.wallet,
      toWallet: to?.wallet,
      amountDisplay,
      network: txNetwork,
      depositAddress: txAddress,
      comment,
      ...(tfa && { tfa }),
      ...(otp && { otp }),
    });
    setStep('success');
    handleCreateTransaction('success');
  };

  if (
    !(
      (couldAddTransaction || couldAddTransactionProposal) &&
      ((from?.account && isFromWallet) ||
        (from?.account && to?.account) ||
        isTransactionStatusOk)
    )
  ) {
    return null;
  }

  const handleNewTransaction = () => {
    handleCreateTransaction('amount');
    setTransactionStatus(null);
    resetTo();
    resetFrom();
  };

  const totalAmount = Number(withdrawFee)
    ? Number(amountDisplay) + Number(withdrawFee)
    : Number(amountDisplay);

  const errorText = getTransferError(amount, rates, user, from, to);

  const handleOpenTransferArea = () => {
    if (label === 'Propose transaction') {
      setOpened(!opened);
    }
  };

  return (
    <S.TransferBar
      initial={{ opacity: 0, y: 30 }}
      animate={{ opacity: 1, y: 0, transition: { duration: 0.5 } }}
      exit={{ opacity: 0, y: 30, transition: { duration: 0.5 } }}
      ready={bothSelected || isFromMultisig}
      isOpened={opened}
    >
      {opened || (isTransactionStatusOk && opened) ? (
        <S.TransferBarBody
          initial={{ opacity: 0 }}
          animate={{ opacity: 1, transition: { duration: 0.5 } }}
          exit={{ opacity: 0, transition: { duration: 0.5 } }}
        >
          {(from && to) || isTransactionStatusOk || step === 'amount' ? (
            management ? (
              <S.ManagementWrap>
                <S.TransferHeader>
                  <S.TransferTitle>Address management</S.TransferTitle>
                  <S.TransferBackToTransaction
                    onClick={() => setManagement(false)}
                  >
                    <S.IconBack />
                    Back to transaction
                  </S.TransferBackToTransaction>
                </S.TransferHeader>
                <AddressManagement
                  walletFrom={from?.wallet}
                  accountFrom={from?.account}
                  walletTo={to?.wallet}
                  accountTo={to?.account}
                  management={management}
                  setManagement={setManagement}
                  setTxNetwork={setTxNetwork}
                  setTxAddress={setTxAddress}
                  side={'to'}
                />
              </S.ManagementWrap>
            ) : (
              <>
                {(() => {
                  switch (step) {
                    case 'otp':
                      return (
                        <S.TransferHeader>
                          <S.TransferTitle>
                            Confirm transaction proposal
                          </S.TransferTitle>
                          <S.TransferBackToTransaction
                            onClick={() => {
                              setError(undefined);
                              setStep('amount');
                            }}
                          >
                            <S.IconBack />
                            Back to transaction
                          </S.TransferBackToTransaction>
                        </S.TransferHeader>
                      );
                    case 'success':
                      return (
                        <S.TransferHeader>
                          <S.TransferTitle success={true}>
                            Transaction proposal successful
                          </S.TransferTitle>
                        </S.TransferHeader>
                      );
                    default:
                      return null;
                  }
                })()}
                <AddressManagement
                  walletFrom={from?.wallet}
                  accountFrom={from?.account}
                  walletTo={to?.wallet}
                  accountTo={to?.account}
                  setManagement={setManagement}
                  transaction={transactionStatus}
                  side={'to'}
                  step={step}
                  amount={amountDisplay}
                  setTxNetwork={setTxNetwork}
                  setTxAddress={setTxAddress}
                />
                {((depositAddressIsWhitelisted && !isFromWallet) ||
                  (isCrossWallet && !bothAreFutures) ||
                  isTransactionStatusOk) && (
                  <S.Send>
                    {(() => {
                      switch (step) {
                        case 'amount':
                          return (
                            <>
                              {showFees && (
                                <S.TransferLimit>
                                  {minWithdrawLimit && (
                                    <S.MinLimit isError={isValueMinExceed}>
                                      <C.Label>Minimal amount:</C.Label>
                                      <span>
                                        {parseFloat(minWithdrawLimit)}
                                      </span>
                                    </S.MinLimit>
                                  )}
                                  {withdrawFee && (
                                    <S.Fee>
                                      <C.Label>Network fee:</C.Label>
                                      <span>
                                        {parseFloat(withdrawFee)}{' '}
                                        {chosenCurrency}
                                      </span>
                                    </S.Fee>
                                  )}
                                </S.TransferLimit>
                              )}
                              <S.TransferFooter>
                                <S.AmountWrap>
                                  <C.Label style={{ width: '100%' }}>
                                    Amount:
                                  </C.Label>
                                  <S.AmountInputWrap isError={Boolean(error)}>
                                    <S.IconCurrency
                                      style={{
                                        backgroundImage: `url(${getCurrencyLogo(
                                          chosenCurrency as CurrencyType,
                                        )})`,
                                      }}
                                    />
                                    <S.AmountInput
                                      value={amountDisplay}
                                      type="text"
                                      pattern="^\d{0,24}(\.\d{0,2})?$"
                                      onChange={changeAmount}
                                    />
                                  </S.AmountInputWrap>
                                  <S.SendButton
                                    type="button"
                                    disabled={
                                      !isConfirmAvailable ||
                                      ((!txAddress || txAddress === '') &&
                                        !isCrossWallet)
                                    }
                                    onClick={() =>
                                      handleCreateTransaction('otp')
                                    }
                                  >
                                    <span>Create transaction</span>
                                    <S.IconSend />
                                  </S.SendButton>
                                </S.AmountWrap>
                              </S.TransferFooter>
                              {errorText && (
                                <S.InsufficientFunds>
                                  {errorText}
                                </S.InsufficientFunds>
                              )}
                            </>
                          );
                        case 'otp':
                          return (
                            <S.TransferFooter>
                              <CreateOtpModal
                                fromWallet={from?.wallet}
                                toWallet={to?.wallet}
                                amount={String(amount)}
                                currency={chosenCurrency || ''}
                                fromAccount={from?.account}
                                toAccount={to?.account}
                                isOtpRequired={appSettings.is_hotp_enabled}
                                error={error}
                                exchangeName={from?.account?.exchange}
                                onSubmit={submitAfterOtp}
                                isSubmitting={isSubmitting}
                                isTfaRequired={isTfaRequired}
                              />
                            </S.TransferFooter>
                          );
                        case 'success':
                          return (
                            <S.TransferFooter style={{ alignItems: 'center' }}>
                              <S.TransactionsLink href="/transactions">
                                View in transaction list
                              </S.TransactionsLink>
                              <S.SendButton
                                type="button"
                                onClick={handleNewTransaction}
                                style={{ padding: '10px 20px' }}
                              >
                                <span>New transaction</span>
                              </S.SendButton>
                            </S.TransferFooter>
                          );
                        default:
                          return null;
                      }
                    })()}
                  </S.Send>
                )}
                {toExchange && !isChosenCurrencyAvailable ? (
                  <div tw="mt-1 text-gray-700">
                    <span tw="capitalize">{toExchange}</span> doesn't accept{' '}
                    {chosenCurrency}
                  </div>
                ) : null}
              </>
            )
          ) : null}
        </S.TransferBarBody>
      ) : null}
      <S.TransferBarLabel
        ready={
          (depositAddressIsWhitelisted && !isFromWallet) ||
          (isCrossWallet &&
            !bothAreFutures &&
            from?.account?.exchange === 'BINANCE') ||
          isTransactionStatusOk
        }
        onClick={handleOpenTransferArea}
      >
        {label}

        {label === 'Propose transaction' && (
          <S.IconButton opened={opened}>
            <S.IconTransferArrow />
          </S.IconButton>
        )}
      </S.TransferBarLabel>
    </S.TransferBar>
  );
});
