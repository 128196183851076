import {
  Transaction,
  TransactionAccount,
  TransactionStatus,
} from 'services/Transactions/types';

export type AccountRequiredType = TransactionAccount;

type AccountExchangeInfoType = {
  currency: string;
  id: number;
  is_internal: boolean;
  max_value: string | null;
  min_value: string | null;
  name: string;
};

export const TagTypes = ['LE', 'WALLET', 'BO'] as const;
export type TagType = (typeof TagTypes)[number];

export const tagColor = {
  LE: 'var(--success)',
  WALLET: 'var(--accent-primary)',
  BO: 'var(--accent-secondary)',
};

export type Tag = {
  type: TagType;
  value: string;
};

export type Threshold = {
  min_amount: number;
  max_amount: number;
  current_amount: number;
  is_violated: boolean;
};

export type Thresholds = {
  specific: {
    [key in CurrencyCryptoType]?: Threshold;
  };
  total: {
    [key in CurrencyFiatType]?: Threshold;
  };
};

export type ColumnSide = 'from' | 'to';

export type AccountType = AccountRequiredType & {
  balance_updated_at: string | null;
  hint: string;
  is_balance_outdated: boolean;
  is_multisig: boolean;
  master_id: number | null;
  sgx_aliases: Array<string> | null;
  wallets: WalletType[];
  exchange_infos?: AccountExchangeInfoType[];
  tags: Tag[];
  thresholds?: Thresholds;
};

export type AppSettingsType = {
  application_direct_link: string;
  application_playmarket_link: string;
  currencies_primary: (CurrencyFiatType | CurrencyCryptoType)[];
  currencies_secondary: CurrencyAltCryptoType[];
  google_auth_key: string;
  is_hotp_enabled: boolean;
  gnosis_node: string;
  gnosis_chain_id: string;
  overview_decimals: Record<string, any>;
  gnosis_enabled: boolean;
  proposal_votes_value_to_reject: number;
  proposal_votes_value_to_approve: number;
};

export type AuthSuccessType = UserInfoType & {
  token: string;
};

export const CurrencyAltCryptoTypes = [
  'AAVE',
  'ADA',
  'ATOM',
  'AVAX',
  'AXS',
  'BAT',
  'COMP',
  'CRV',
  'DAI',
  'DCR',
  'DOGE',
  'DYDX',
  'FET',
  'FIL',
  'FTM',
  'FTT',
  'GRT',
  'GOR',
  'ICP',
  'LEO',
  'LINK',
  'LTC',
  'MANA',
  'MATIC',
  'MKR',
  'OMG',
  'ONT',
  'QTUM',
  'REP',
  'REN',
  'SAND',
  'SNX',
  'SHIB',
  'SUSHI',
  'STORJ',
  'USDC',
  'UNI',
  'UST',
  'XTZ',
  'ZRX',
  'CVX',
  'XMR',
  'SOL',
  'NEO',
  'DASH',
  'DOT',
  'ETC',
  'ZEC',
  'IOTA',
  'EGLD',
  'AMP',
  'FLOW',
  'GMX',
  'HNT',
  'IMX',
  'OP',
  'SKL',
  'UMA',
] as const;
export const CurrencyCryptoTypes = [
  'BTC',
  'ETH',
  'NATIVE_COIN',
  'EURS',
  'USDT',
  'BUSD',
] as const;
export const CurrencyFiatTypes = ['EUR', 'USD'] as const;

export type CurrencyAltCryptoType = (typeof CurrencyAltCryptoTypes)[number];
export type CurrencyCryptoType = (typeof CurrencyCryptoTypes)[number];
export type CurrencyFiatType = (typeof CurrencyFiatTypes)[number];

export type CurrencyType =
  | CurrencyAltCryptoType
  | CurrencyCryptoType
  | CurrencyFiatType;

export const isCurrencyFiat = (
  value: CurrencyType,
): value is CurrencyFiatType =>
  CurrencyFiatTypes.map((item) => item.toUpperCase()).includes(
    value as CurrencyFiatType,
  ) ||
  CurrencyFiatTypes.map((item) => item.toLowerCase()).includes(
    value as CurrencyFiatType,
  );

export type CurrencyInfoType = {
  api_id: CurrencyType;
  title: string;
  logo_url: string;
};

export type DepositAddressType = {
  currency: CurrencyType;
  sgx_id: string;
  deposit_address: string;
  network: string;
};

export type ErrorType = {
  error: string;
  error_details?: string[];
};

export type ExchangeAccountsType = {
  exchange: ExchangeApiIdType;
  accounts: AccountType[];
};

export type ExchangeStatusType = {
  exchange: string;
  ok: boolean;
  time: Date;
};

export type ExchangeApiIdType =
  | 'WALLETS'
  | 'BINANCE'
  | 'BITSTAMP'
  | 'BITFINEX'
  | 'DERIBIT'
  | 'GLOBITEX'
  | 'HITBTC'
  | 'GOZO'
  | 'KRAKEN'
  | 'LMAX'
  | 'OKCOIN'
  | 'FTX'
  | 'DYDX'
  | 'OKX'
  | 'KUCOIN';

export type ExchangeInfoType = {
  api_id: ExchangeApiIdType;
  title: string;
  logo_url: string;
};

export type PermissionType =
  | 'accounts.view_account'
  | 'accounts.change_account'
  | 'transactions.add_transaction'
  | 'transactions.view_transaction'
  | 'transaction_proposals.add_transactionproposal'
  | 'transaction_proposals.resolve_transactionproposal'
  | 'transaction_proposals.view_transactionproposal'
  | 'mpc.add_mpcgroup';

export type StatusProposalChange = 'vote-down' | 'vote-up';

export const StatusType = TransactionStatus;

export enum StatusProposalType {
  INITIATED = 'INITIATED',
  REJECTED = 'REJECTED',
  APPROVED = 'APPROVED',
}

interface ProposalUserVote {
  user: string;
  vote: number;
}

export type TransactionStatusType =
  | null
  | ({ api_creation_status: 'ok' } & Transaction)
  | { api_creation_status: 'error'; error: string; error_details?: string[] };

type PeriodsLimits = {
  WEEK: string;
  DAY: string;
};

export type UserInfoType = {
  email: string;
  first_name: string;
  last_name: string;
  permissions: PermissionType[];
  has_hotp_secret: boolean;
  proposal_vote_weight: number;
  period_limits: PeriodsLimits;
  period_payments: PeriodsLimits;
  single_transaction_limit: string;
};

export type WalletType = {
  id: number;
  currency: CurrencyType;
  total: string;
  available: string;
  deposit_address: string;
  deposit_addresses?: [];
  type: string | null;
  tags: Tag[];
  isWhitelisted?: boolean;
};

export type TransferExchangeFilterType = {
  exchange_type: string | null;
  name: string;
};
