import { useContext, useEffect, useState } from 'react';

import Tippy from '@tippyjs/react';

import { API_URL } from 'shared/constants/app';

import AppContext from 'shared/contexts/AppContext';

import { TabWrap, TabItem } from 'shared/components';
import {
  CurrencyType,
  DepositAddressType,
  ExchangeApiIdType,
  isCurrencyFiat,
} from 'shared/types';

import { getCurrencyLogo } from 'shared/helpers/currencies';
import { getExchangeInfoById } from 'shared/constants/exchanges';
import { useWindowSize } from 'shared/hooks/useWindowSize';

import * as S from './styled';
import { formatAccount } from 'shared/helpers/format';
import { QrModal } from 'features/Profile/view/components/QrModal';
import { isDepositAddressAvailable } from 'shared/helpers/transaction';
import {
  ApprovedIcon,
  RejectedIcon,
} from 'features/Transactions/view/styled';

type TabType = 'exchange' | 'unbound' | 'standalone';

const DESCRIPTIONS = {
  exchange:
    'Here are the exchange addresses. Adding an address to the whitelist allows you to work with the address through Multik (make withdrawals, deposits, etc.)',
  unbound:
    'Here are addresses that are added to the Multik, but these addresses are not found in the deposit addresses of exchanges.',
  standalone:
    'Here are the addresses of cold cryptowallets (which are not associated with exchanges). Adding an address to the whitelist allows you to make deposits to this address.',
};

const WHITELIST_TABS: { title: string; tab_alias: TabType }[] = [
  {
    title: 'Exchange addresses',
    tab_alias: 'exchange',
  },
  {
    title: 'Unbound addresses',
    tab_alias: 'unbound',
  },
  {
    title: 'Standalone addresses',
    tab_alias: 'standalone',
  },
];

export const Whitelists = () => {
  const [depositAddresses, setDepositAddresses] = useState<
    DepositAddressType[]
  >([]);
  const [tab, setTab] = useState<TabType>('exchange');

  const {
    accounts,
    appToken,
    checkExpiredToken,
    getAccountById,
    scrollToTop,
    openModal,
  } = useContext(AppContext);

  const TEST_HEADERS = {
    headers: {
      authorization: `Token ${appToken}`,
    },
  };

  const { width } = useWindowSize();

  useEffect(() => {
    scrollToTop();
    getDepositAddresses();
  }, []);

  function handleSetTab(value: string) {
    if (tab !== value) {
      setTab(value as TabType);
      scrollToTop();
    }
  }

  async function getDepositAddresses() {
    const rawData = await fetch(`${API_URL}/whitelist/`, TEST_HEADERS);

    checkExpiredToken(rawData);

    const data = await rawData.json();

    setDepositAddresses(data || []);
  }

  const extendedCryptoWallets = accounts
    .map((account) =>
      account.wallets
        .filter((item) => !isCurrencyFiat(item.currency))
        .map((item) => ({
          ...item,
          accountId: account.id,
          exchangeId: account.exchange,
          masterId: account.master_id,
        })),
    )
    .flat();

  const exchangeWallets = extendedCryptoWallets
    .filter((wallet) => wallet.exchangeId !== 'WALLETS')
    .reduce((wallets: any[], wallet: any) => {
      const mapped: any[] = wallet?.deposit_addresses?.map(
        ({ deposit_address, network }: any) => {
          const isConfirmed = Boolean(
            depositAddresses.find(
              (address) => deposit_address === address.deposit_address,
            ),
          );
          return {
            ...wallet,
            deposit_address,
            network,
            isConfirmed,
          };
        },
      );
      return [...wallets, ...(mapped || [])];
    }, []);

  const unboundAddresses =
    extendedCryptoWallets.length > 0
      ? depositAddresses.filter(
          (address) =>
            !isCurrencyFiat(address.currency) &&
            !extendedCryptoWallets.find(
              (wallet) =>
                wallet.deposit_addresses?.find(
                  ({ deposit_address }) =>
                    deposit_address === address.deposit_address,
                ) && address.currency === wallet.currency,
            ),
        )
      : [];

  const standaloneWallets = extendedCryptoWallets
    .filter((wallet) => wallet.exchangeId === 'WALLETS')
    .reduce((wallets: any[], wallet: any) => {
      const mapped: any[] = wallet?.deposit_addresses?.map(
        ({ deposit_address, network }: any) => {
          const isConfirmed = Boolean(
            depositAddresses.find(
              (address) => deposit_address === address.deposit_address,
            ),
          );
          return {
            ...wallet,
            deposit_address,
            network,
            isConfirmed,
          };
        },
      );
      return [...wallets, ...(mapped || [])];
    }, []);

  const isTippyEnabled = width ? width > 1125 : false;

  return (
    <S.Wrap
      initial={{ opacity: 0 }}
      animate={{ opacity: 1, transition: { duration: 0.5 } }}
      exit={{ opacity: 0, transition: { duration: 0.5 } }}
    >
      <S.Header>
        <TabWrap style={{ paddingLeft: '20px' }}>
          {WHITELIST_TABS.map((item) => {
            return (
              <TabItem
                key={item?.tab_alias}
                active={String(tab) === item?.tab_alias}
                onClick={() => handleSetTab(item?.tab_alias)}
              >
                {item.title}
              </TabItem>
            );
          })}
        </TabWrap>
        <S.TabDescription>{DESCRIPTIONS[tab]}</S.TabDescription>
        <S.Table>
          <S.TableHeaderRow>
            {tab === 'exchange' || tab === 'standalone' ? (
              <>
                <S.ColExchange>
                  <S.TableHeaderTitle>Exchange</S.TableHeaderTitle>
                </S.ColExchange>
                <S.ColAccount>
                  <S.TableHeaderTitle>Account</S.TableHeaderTitle>
                </S.ColAccount>
              </>
            ) : tab === 'unbound' ? (
              <S.ColAlias>
                <S.TableHeaderTitle>Alias</S.TableHeaderTitle>
              </S.ColAlias>
            ) : null}
            <S.ColCoin>
              <S.TableHeaderTitle>Coin</S.TableHeaderTitle>
            </S.ColCoin>
            <S.ColCoin>
              <S.TableHeaderTitle>Network</S.TableHeaderTitle>
            </S.ColCoin>
            {tab === 'exchange' || tab === 'standalone' ? (
              <S.ColCoin>
                <S.TableHeaderTitle>Whitelisted</S.TableHeaderTitle>
              </S.ColCoin>
            ) : null}
            <S.ColDeposit>
              <S.TableHeaderTitle>Deposit address</S.TableHeaderTitle>
            </S.ColDeposit>
          </S.TableHeaderRow>
        </S.Table>
      </S.Header>
      {tab === 'exchange' || tab === 'standalone' ? (
        <S.Table>
          {(tab === 'exchange' ? exchangeWallets : standaloneWallets)
            .filter((item) =>
              isDepositAddressAvailable(
                item.exchangeId as ExchangeApiIdType,
                item.type as string,
                item.deposit_addresses,
              ),
            )
            .sort((a, b) => a.exchangeId.localeCompare(b.exchangeId))
            .sort((a, b) => Number(a.isConfirmed) - Number(b.isConfirmed))
            .map((item) => {
              const exchangeInfo = getExchangeInfoById(item.exchangeId);
              const accountInfo = getAccountById(item.accountId);
              const masterAccountInfo = item.masterId
                ? getAccountById(item.masterId)
                : null;

              const depositAddress =
                // @ts-ignore
                item?.deposit_addresses?.[0]?.deposit_address;

              const openQrModal = () => {
                openModal({
                  title: 'Scan QR Code',
                  component: () => (
                    <QrModal
                      encoded={`${depositAddress},${item.currency}`}
                      decoded={`${depositAddress},${item.currency}`}
                    />
                  ),
                });
              };

              return item.currency !== 'USD' && item.currency !== 'EUR' ? (
                <S.Row key={item.id}>
                  <S.ColExchange>
                    <S.ExchangeLogo
                      style={{
                        backgroundImage: `url(${exchangeInfo?.logo_url})`,
                      }}
                    />
                    <S.ExchangeName>{exchangeInfo?.title}</S.ExchangeName>
                  </S.ColExchange>
                  <S.ColAccount>
                    {masterAccountInfo ? (
                      <>
                        <S.ColMasterInfo>
                          {masterAccountInfo?.name}
                        </S.ColMasterInfo>
                        <S.ColSubInfo>
                          {accountInfo?.name}
                          {item.type ? `, ${item.type}` : null}
                        </S.ColSubInfo>
                      </>
                    ) : (
                      <>
                        <S.ColMasterInfo title={accountInfo?.name}>
                          {formatAccount(accountInfo?.name)}
                        </S.ColMasterInfo>
                        {item.type ? (
                          <S.ColSubInfo>{item.type}</S.ColSubInfo>
                        ) : null}
                      </>
                    )}
                  </S.ColAccount>
                  <S.ColCoin>
                    <S.CoinLogo
                      style={{
                        backgroundImage: `url(${getCurrencyLogo(
                          item.currency as CurrencyType,
                        )})`,
                      }}
                    />
                    {item.currency}
                  </S.ColCoin>
                  <S.ColCoin>{item.network}</S.ColCoin>
                  <S.ColCoin>
                    <S.IconWrapper>
                      {item.isConfirmed ? <ApprovedIcon /> : <RejectedIcon />}
                    </S.IconWrapper>
                  </S.ColCoin>
                  <Tippy
                    trigger="click"
                    placement="top"
                    disabled={isTippyEnabled}
                    hideOnClick={true}
                    theme="transparent"
                    content={<S.TippyText>{depositAddress}</S.TippyText>}
                  >
                    <S.ColDeposit>
                      {depositAddress && (
                        <>
                          <S.IconQr onClick={openQrModal} />
                          <S.DepositAddress title={depositAddress}>
                            {depositAddress}
                          </S.DepositAddress>
                        </>
                      )}
                    </S.ColDeposit>
                  </Tippy>
                </S.Row>
              ) : null;
            })}
        </S.Table>
      ) : null}

      {tab === 'unbound' ? (
        <S.Table>
          {unboundAddresses
            .sort((a, b) => a.sgx_id.localeCompare(b.sgx_id))
            .map((item) => {
              const openQrModal = () => {
                openModal({
                  title: 'Scan QR Code',
                  component: () => (
                    <QrModal
                      encoded={`${item.deposit_address},${item.currency}`}
                      decoded={`${item.deposit_address},${item.currency}`}
                    />
                  ),
                });
              };

              return (
                <S.Row key={`${item.sgx_id}_${item.currency}`}>
                  <S.ColAlias>{item.sgx_id}</S.ColAlias>
                  <S.ColCoin>
                    <S.CoinLogo
                      style={{
                        backgroundImage: `url(${getCurrencyLogo(
                          item.currency as CurrencyType,
                        )})`,
                      }}
                    />
                    {item.currency}
                  </S.ColCoin>
                  <S.ColCoin>{item.network}</S.ColCoin>
                  <Tippy
                    trigger="click"
                    placement="top"
                    hideOnClick={true}
                    disabled={isTippyEnabled}
                    theme="transparent"
                    content={<S.TippyText>{item?.deposit_address}</S.TippyText>}
                  >
                    <S.ColDeposit>
                      {item?.deposit_address && (
                        <>
                          <S.IconQr onClick={openQrModal} />
                          <S.DepositAddress title={item?.deposit_address}>
                            {item?.deposit_address}
                          </S.DepositAddress>
                        </>
                      )}
                    </S.ColDeposit>
                  </Tippy>
                </S.Row>
              );
            })}
        </S.Table>
      ) : null}
    </S.Wrap>
  );
};
