export enum ErrorCodes {
  SGX_BASE = 'sgx_base',
  EXCHANGE_BASE = 'exchange_base',
  EXCHANGE_OTP_ERROR = 'exchange_otp_error',
  VALIDATION_ERROR = 'validation_error',
  AUTHENTICATION_FAILED = 'authentication_failed',
  NOT_AUTHENTICATED = 'not_authenticated',
  OTP_ERROR = 'otp_error',
  PERMISSION_DENIED = 'permission_denied',
  NOT_FOUND = 'not_found',
  METHOD_NOT_ALLOWED = 'method_not_allowed',
  NOT_ACCEPTABLE = 'not_acceptable',
  UNSUPPORTED_MEDIA_TYPE = 'unsupported_media_type',
  THROTTLED = 'throttled',
  UNEXPECTED_ERROR = 'unexpected_error',
  EXCHANGE_IS_NOT_SUPPORTED_ACTION = 'exchange_is_not_supported_action',
  EXCHANGE_WHITELIST_ERROR = 'exchange_whitelist_error',
  EXCHANGE_NONCE_ERROR = 'exchange_nonce_error',
  EXCHANGE_INVALID_AMOUNT_ERROR = 'exchange_invalid_amount_error',
  EXCHANGE_PERMISSION_ERROR = 'exchange_permission_error',
  EXCHANGE_INVALID_DIRECTION_ERROR = 'exchange_invalid_dirrection_error',
}

export const errorMessages = {
  [ErrorCodes.SGX_BASE]: '',
  [ErrorCodes.EXCHANGE_BASE]: 'Withdrawal address does not exist',
  [ErrorCodes.EXCHANGE_OTP_ERROR]:
    'The TFA code you’ve entered for the exchange is incorrect. Please review it and try again. If the issue persists, please contact us at support@multik.io',
  [ErrorCodes.VALIDATION_ERROR]: 'Validation error',
  [ErrorCodes.AUTHENTICATION_FAILED]: '',
  [ErrorCodes.NOT_AUTHENTICATED]: '',
  [ErrorCodes.OTP_ERROR]: 'The OTP code you have entered is invalid',
  [ErrorCodes.PERMISSION_DENIED]: 'Permission denied',
  [ErrorCodes.NOT_FOUND]: '',
  [ErrorCodes.METHOD_NOT_ALLOWED]: '',
  [ErrorCodes.NOT_ACCEPTABLE]: '',
  [ErrorCodes.UNSUPPORTED_MEDIA_TYPE]: '',
  [ErrorCodes.THROTTLED]: '',
  [ErrorCodes.UNEXPECTED_ERROR]: '',
  [ErrorCodes.EXCHANGE_IS_NOT_SUPPORTED_ACTION]:
    'This action is not supported by exchange',
  [ErrorCodes.EXCHANGE_WHITELIST_ERROR]:
    'The deposit address you\'ve provided isn\'t found on your exchange account\'s whitelist. Please reach out to your administrator to have the deposit address added to the whitelist.',
  [ErrorCodes.EXCHANGE_NONCE_ERROR]:
    'The nonce associated with your request is no longer valid. Please re-submit your request. If you continue to encounter issues, don\'t hesitate to contact us at support@multik.io.',
  [ErrorCodes.EXCHANGE_INVALID_AMOUNT_ERROR]:
    'Your account lacks sufficient funds to complete this transfer. Please check your balance and attempt the transfer again.',
  [ErrorCodes.EXCHANGE_PERMISSION_ERROR]:
    'The API keys for your exchange account are either invalid or lack the necessary permissions. Please double-check your API keys and their permissions on the exchange. If you continue to have trouble, feel free to contact us at support@multik.io.',
  [ErrorCodes.EXCHANGE_INVALID_DIRECTION_ERROR]:
    'Transfers between these wallets are not permitted on the exchange. Please review the supported transfer directions before attempting again. If you still face issues, don\'t hesitate to reach out to us at support@multik.io.',
};

export const parseError = (err: any, info?: any) => {
  const data = err?.response?.data;
  const { fromWallet, toWallet } = info || {};

  if (
    [ErrorCodes.EXCHANGE_INVALID_AMOUNT_ERROR].includes(data?.error_code) &&
    (data?.error_message?.includes('Minimum withdrawal') || data?.error_message?.includes('Mininum withdrawal')) &&
    data?.error_message?.includes('BITFINEX')
  ) {
    return `${data?.error_message.split(': ')[1]} ${toWallet.currency}`;
  }

  if ([ErrorCodes.EXCHANGE_OTP_ERROR].includes(data?.error_code)) {
    const errorCode = data?.error_code;
    return errorMessages[errorCode as ErrorCodes];
  }

  if ([ErrorCodes.EXCHANGE_BASE].includes(data?.error_code)) {
    try {
      const errorData = JSON.parse(data?.error_message)?.error_message;

      if (errorData.includes('returned error')) {
        // костыль до обновлений с бека
        const errorParsedData = errorData.split(' returned error: ');
        const exchange = errorParsedData[0];
        let errorText = errorParsedData[1];

        if (errorText.includes('code') && errorText.includes('-1102')) {
          if (errorText.includes('\'amount\'')) {
            errorText = 'The amount is malformed.';
          } else if (errorText.includes('\'type\'')) {
            errorText = `Transfers between ${fromWallet.type} and ${toWallet.type} wallets are not available in both directions. Please, select another deposit wallet.`;
          }

          // eslint-disable-next-line
        } else if (errorText.includes("{'code':")) {
          errorText =
            JSON.parse(errorText.replace(/'/g, '"'))?.[0]?.message ||
            'Transaction error';
        } else if (exchange === 'DERIBIT') {
          if (errorText.includes('12998')) {
            // Error code: security_key_authorization_over_limit
            errorText =
              'TFA code limit reached - please wait before attempting to submit your code again';
          } else {
            errorText = JSON.parse(
              errorText.replace(/'/g, '"'),
            )?.data?.reason?.replace(/_/g, ' ');
          }
        }

        return `${exchange}: ${errorText}`;
      }
      return errorData;
    } catch (error) {
      // Если error_message не является JSON, возвращаем его напрямую
      const errorMessage = data?.error_message;

      // Если сообщение об ошибке содержит 'invalid'
      if (errorMessage && errorMessage.toLowerCase().includes('invalid')) {
        // Используем регулярное выражение, чтобы найти адрес в сообщении об ошибке
        const match = errorMessage.match(/\b0x[a-fA-F0-9]{40}\b/);
        if (match) {
          return `Invalid ethereum address (${match[0]})`;
        }
      }

      return errorMessage;
    }
  }

  return (
    errorMessages[data?.error_code as ErrorCodes] ||
    data?.error_message ||
    err?.message
  );
};
